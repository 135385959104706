<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :default-table-headers="[]"
          :form-scheme="formScheme"
          :resource="resource"
        >
          <template #[`item.email`]="{ item }">
            <div class="pa-2">
              <div class="mb-1">
                {{ item.email }}
              </div>
              <div class="grey--text small">
                {{ item.notes || '' }}
              </div>
            </div>
          </template>
          <template #[`item.url`]="{ item }">
            <div class="pa-2">
              <v-icon
                @click="openWindow(item.url)"
              >
                mdi-search-web
              </v-icon>
            </div>
          </template>
          <template #action-buttons="{ item }">
            <v-btn
              v-can="'emails.auth'"
              icon
              @click="getCredentials(item.id)"
            >
              <v-icon small>
                mdi-key-chain-variant
              </v-icon>
            </v-btn>
          </template>
        </table-data>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Emails',
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('emails.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'emails',
    editedItem: {}
  }),
  async mounted () {
    this.title = this.$t('emails.title')

    let actionsColWight = 20
    actionsColWight += this.$can('emails.auth') ? 40 : 0
    actionsColWight += this.$can('emails.update') ? 40 : 0
    actionsColWight += this.$can('emails.remove') ? 40 : 0

    this.tableHeaders = [
      {
        text: this.$t('emails.table.email'),
        align: 'start',
        value: 'email'
      },
      { text: this.$t('emails.table.provider'), value: 'provider', width: '100px', align: 'center' },
      { text: this.$t('emails.table.url'), value: 'url', width: '90px', align: 'center', sortable: false },
      {
        text: this.$t('tableData.creationDate'),
        align: 'center',
        value: 'createdAt',
        width: '170px'
      },
      {
        text: this.$t('tableData.actions'),
        align: 'center',
        value: 'actions',
        sortable: false,
        width: `${actionsColWight}px`
      }
    ]

    // if (this.$can('emails.auth')) {
    //   this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
    //     text: this.$t('emails.table.credentials'),
    //     value: 'credentials',
    //     sortable: false,
    //     width: '100px',
    //     align: 'center'
    //   }, 5)
    // }

    this.formScheme = {
      email: {
        type: 'text',
        label: this.$t('emails.tableForm.email'),
        createRules: 'req',
        updateRules: []
      },
      password: {
        type: 'password',
        label: this.$t('emails.tableForm.password'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: [],
        updateRules: []
      },
      provider: {
        type: 'text',
        label: this.$t('emails.tableForm.provider'),
        createRules: [],
        updateRules: []
      },
      url: {
        type: 'text',
        label: this.$t('emails.tableForm.url'),
        createRules: [],
        updateRules: []
      },
      notes: {
        type: 'textarea',
        label: this.$t('emails.tableForm.notes'),
        'auto-grow': true,
        rows: 1,
        createRules: [],
        updateRules: [],
        md: 12
      }
    }
    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, { text: this.$t('emails.table.team'), value: 'team' }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
        type: 'autocomplete',
        label: this.$t('proxies.tableForm.team'),
        items: await this.$store.dispatch('affiliatesTeams/all'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 12
      }, 'notes')
    }
  },
  methods: {
    openWindow (url) {
      window.open(url, '_blank')
    },
    async getCredentials (id) {
      if (!this.$can('emails.auth')) {
        return
      }
      const result = await this.$store.dispatch('emails/getEmailCredentials', { id })
      if (result.id) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('dialog.credentials'),
          contentType: 'code',
          content: JSON.stringify(result, null, 2),
          maxWidth: 500
        })
      }
    }
  }
}
</script>

<style scoped></style>
